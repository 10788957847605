var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 订单列表("+_vm._s(_vm.name)+") "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning","dark":"","small":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v("mdi-arrow-left")]),_vm._v("返回 ")],1)],1),_c('v-card-subtitle',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticStyle:{"line-height":"40px","color":"#fb8c00","font-size":"16px"}},[_c('strong',[_vm._v(" 总额："+_vm._s((_vm.totalAmount||0)/100)+"元")])]),_c('span',{staticStyle:{"line-height":"40px","color":"#000000de","margin-left":"20px","font-size":"16px"}},[_vm._v("总订单数:"+_vm._s(_vm.totalCount))])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.statusArr,"dense":"","label":"订单状态"},on:{"change":_vm.getList},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"disable-sort":"","items":_vm.orderList,"loading":_vm.loading,"hide-default-footer":"","server-items-length":_vm.form.limit},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.expire?_vm.$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"):''))])]}},{key:"item.paid_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.expire?_vm.$moment(item.paid_at).format("YYYY-MM-DD HH:mm:ss"):''))])]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [(item.level==2)?_c('span',{staticStyle:{"color":"#bf7a15"}},[_vm._v("高级会员")]):_vm._e(),(item.level==1)?_c('span',[_vm._v("普通会员")]):_vm._e()]}},{key:"item.rmb_fee",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s((item.rmb_fee||0)/100))])]}},{key:"item.channel",fn:function(ref){
var item = ref.item;
return [(item.channel=='alipay')?_c('span',[_vm._v("支付宝")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status=='SUCCESS')?_c('span',{staticStyle:{"color":"green"}},[_vm._v("付款成功")]):_vm._e(),(item.status=='PAYING')?_c('span',[_vm._v("支付中")]):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","xs":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":"5"},on:{"input":_vm.getList},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","xs":"12"}},[_c('v-select',{attrs:{"items":_vm.limits,"label":"条数","dense":""},on:{"change":_vm.limitChange},model:{value:(_vm.form.limit),callback:function ($$v) {_vm.$set(_vm.form, "limit", $$v)},expression:"form.limit"}})],1)],1),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }