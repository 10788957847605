<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>
                        订单列表({{name}})
                        <v-spacer></v-spacer>
                        <v-btn color="warning" dark small @click="$router.go(-1)">
                            <v-icon small dark>mdi-arrow-left</v-icon>返回
                        </v-btn>
                    </v-card-title>
                    <v-card-subtitle>
                        <v-row>
                            <v-col cols="12" md="6">
                                <span style="line-height:40px;color:#fb8c00 ;font-size:16px;"><strong> 总额：{{(totalAmount||0)/100}}元</strong></span>
                                <span style="line-height:40px;color:#000000de;margin-left:20px;font-size:16px;">总订单数:{{totalCount}}</span>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select :items="statusArr" v-model="form.status" dense @change="getList" label="订单状态"></v-select>
                            </v-col>
                        </v-row>
                    </v-card-subtitle>
                    <v-card-text>
                        <v-data-table :headers="headers" disable-sort :items="orderList" :loading="loading" hide-default-footer :server-items-length="form.limit" class="elevation-1">
                            <template v-slot:item.created_at="{ item }">
                                <span>{{item.expire?$moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"):''}}</span>
                            </template>
                            <template v-slot:item.paid_at="{ item }">
                                <span>{{item.expire?$moment(item.paid_at).format("YYYY-MM-DD HH:mm:ss"):''}}</span>
                            </template>
                            <template v-slot:item.level="{ item }">
                                <span v-if="item.level==2" style="color:#bf7a15">高级会员</span>
                                <span v-if="item.level==1">普通会员</span>
                            </template>
                            <template v-slot:item.rmb_fee="{ item }">
                                <span>{{(item.rmb_fee||0)/100}}</span>
                            </template>
                            <template v-slot:item.channel="{ item }">
                                <span v-if="item.channel=='alipay'">支付宝</span>
                            </template>
                            <template v-slot:item.status="{ item }">
                                <span v-if="item.status=='SUCCESS'" style="color:green">付款成功</span>
                                <span v-if="item.status=='PAYING'">支付中</span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                        <v-row>
                            <v-col cols="12" md="6" xs="12">
                                <v-pagination v-model="currentPage" :length="pageCount" total-visible="5" @input="getList"></v-pagination>
                            </v-col>
                            <v-col cols="12" md="6" xs="12">
                                <v-select :items="limits" v-model="form.limit" label="条数" dense @change="limitChange"></v-select>
                            </v-col>
                        </v-row>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
    // @ is an alias to /src
    import { post, tips, limits } from '@/facade';
    export default {
        data() {
            return {
                loading: true,
                form: {
                    nid: '',
                    limit: 20,
                    offset: 0,
                    status: 'SUCCESS'
                },
                currentPage: 1,
                pageCount: 1,
                limits: limits,
                headers: [
                    { text: '订单号', value: "no" },
                    { text: '名称', value: "name" },
                    { text: '金额(元)', value: "rmb_fee" },
                    { text: '类型', value: "type" },
                    { text: '策略', value: "policy" },
                    { text: '支付通道', value: "channel" },
                    { text: '状态', value: "status" },
                    { text: '等级', value: "level" },
                    { text: '平台', value: "platform" },
                    { text: '创建时间', value: "created_at" },
                    { text: '支付时间', value: "paid_at" },
                    // { text: '操作', value: 'option' },
                ],
                orderList: [],
                name: "",
                statusArr: [
                    { text: '成功订单', value: 'SUCCESS' },
                    { text: '支付中订单', value: 'PAYING' },
                ],
                totalAmount: '',
                totalCount: 0
            }
        },
        async created() {
            const info = localStorage.getItem('netInfo')
            this.netInfo = JSON.parse(info);
            this.name = this.netInfo.name;
            this.form.nid = this.netInfo.id;
             await this.getList()
            // if (this.$route.query.id) {
            //     this.form.nid = this.$route.query.id;
            //     this.form.cid = this.$route.query.cid;
            //     this.name = this.$route.query.name;
            //     if (this.$route.query.did) this.form.did = this.$route.query.did;
            //     await this.getList()
            // }
        },

        watch: {
            '$route'(to, from) { // 监听路由是否变化
                if (to.query.id !== from.query.id) {
                    this.form.nid = to.query.id;
                    this.name = to.query.name;
                    this.getList()
                }
            }
        },
        methods: {
            /**每页显示条数变化 */
            async limitChange() {
                this.currentPage = 1;
                this.getList();
            },
            // 获取订单列表
            async getList() {
                this.form.offset = this.form.limit * (this.currentPage - 1);
                const data = await post('/vnet/order/list', this.form)
                if (data.code == 'ok') {
                    this.loading = false;
                    this.totalAmount = data.rmb_fee;
                    this.totalCount = data.count;
                    this.orderList = data.orders;
                    this.pageCount = data.count > 0 ? Math.ceil(data.count / this.form.limit) : 1;
                } else {
                    this.loading = false;
                    tips('error', data.message)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .code-mirror-div {
        padding: 2px;
        height: 500px;

        .tool-bar {
            top: 20px;
            margin: 30px 2px 0px 20px;
        }

    }
</style>